<template>
    <div>
        <div class="container" v-if="!showLoader && !errored">
            <div class="row py-4 justify-content-center">
                <div class="col-12">
                    <h5>Active Ad-hoc Observation Campaigns:</h5>
                </div>
            </div>
            <div class="row py-2 justify-content-center">
                <div class="col-12">
                    <div class="table-responsive-md">
                        <table class="table table-dark table-bordered">
                            <thead class="thead-dark text-center">
                                <tr>
                                    <td>Campaign</td>
                                    <td>Description</td>
                                    <td>Link</td>
                                    <td>Events</td>
                                </tr>    
                            </thead>
                            <tbody>
                                <tr v-for="tag in adHocCampaignTags" :key="tag.id">
                                    <td>
                                        <span class="badge badge-owc">{{tag.name}}</span>
                                        
                                    </td>
                                    <td><img class="mx-3" v-if="tag.imgUrl" :src="tag.imgUrl" style="float:left"/><p>&nbsp;&nbsp;&nbsp;&nbsp;{{tag.descr}}</p></td>
                                    <td nowrap><a :href="tag.url" target="_blank">External Web Site</a></td>
                                    <td nowrap>
                                        <router-link :to="{ name: 'CampaignEvents', params: { campaignId: tag.name }}" v-slot="{ href }" custom>
                                            <a :href="href" target="_blank">OWC Events</a>
                                        </router-link>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="row py-4 justify-content-center">
                <div class="col-12">
                    <h5>LuckyStar and RioGroup Campaigns:</h5>
                </div>
            </div>
            <div class="row py-2 justify-content-center">
                <div class="col-12">
                    <div class="table-responsive-md">
                        <table class="table table-dark table-bordered">
                            <thead class="thead-dark text-center">
                                <tr>
                                    <td>Campaign</td>
                                    <td>Description</td>
                                    <td>Events</td>
                                </tr>    
                            </thead>
                            <tbody>
                                <tr v-for="tag in luckyStarCampaignTags" :key="tag.id">
                                    <td>
                                        <span class="badge badge-owc">{{tag.name}}</span>
                                        
                                    </td>
                                    <td><img class="mx-3" v-if="tag.imgUrl" :src="tag.imgUrl" style="float:left"/><p>&nbsp;&nbsp;&nbsp;&nbsp;{{tag.descr}}</p></td>
                                    <td nowrap>
                                        <router-link :to="{ name: 'CampaignEvents', params: { campaignId: tag.name }}" v-slot="{ href }" custom>
                                            <a :href="href" target="_blank">OWC Events</a>
                                        </router-link>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>            
        </div>
        <div class="text-center">
            <b-spinner v-if="showLoader" class="justify-content-center mt-3" varient="secondary" style="width: 3rem; height: 3rem;" ></b-spinner>
            <h5 class="text-danger mt-3" v-if="errored && !showLoader">Error getting observation campaigns</h5>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    data() {
        return {
            showLoader : false,
            errored : false
        }
    }, 
    methods: {
        ...mapActions(['loadCampaignTagsInfo'])
    },
    async mounted() {
        if (!this.campaignTags)
        {
            this.showLoader = true;

            try 
            {
                await this.loadCampaignTagsInfo();
                
                this.showLoader = false;
            }
            catch(error)
            {
                this.showLoader = false;
                this.errored = true;
                this.showToast(error.message, 'ERROR');                    
            }
        }
    },
    computed: {
        adHocCampaignTags(){
            return this.$store.getters.adHocCampaignTags;
        },
        luckyStarCampaignTags(){
            return this.$store.getters.luckyStarCampaignTags;
        },
    }
}
</script>

<style scoped>

</style>