<template>
    <div class="container">
        <div class="row">
            <div class="col-12 pt-4 mx-2" >
              <div class="pr-2 float-left">
                <h5>Occult Watcher Cloud (<i>OWC</i>)</h5>
              </div>
              <div>
                [ <span class="text-muted">version <strong class="text-light">{{ appVersion }}</strong>, built on <strong class="text-light">{{versionDate}}</strong></span> ]
              </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 pt-4 mx-2" >
              <i class="fa fa-asterisk pr-1 yellow-highlight"></i> <span class="text-muted"><i>OWC</i> is supported by <strong class="text-light"><a href="https://occultations.org/" target=_blank>IOTA</a></strong></span>
            </div>             
            <div class="col-12 pt-2 mx-2" >
              <i class="fa fa-asterisk pr-1 yellow-highlight"></i> <span class="text-muted">For prediction generation <i>OWC</i> uses <strong class="text-light"><a href="http://www.lunar-occultations.com/iota/occult4.htm" target=_blank>Occult</a></strong><small v-if="this.occultVersion"> (v{{this.occultVersion}})</small> by <strong class="text-light">Dave Herald</strong></span>
            </div> 
            <div class="col-12 pt-2 mx-2" >
              <i class="fa fa-asterisk pr-1 yellow-highlight"></i> <span class="text-muted"><i>OWC</i> uses star positions provided by <strong class="text-light"><a href="https://sci.esa.int/web/gaia" target=_blank>Gaia</a></strong></span>
            </div>
            <div class="col-12 pt-2 mx-2" >
              <i class="fa fa-asterisk pr-1 yellow-highlight"></i> <span class="text-muted"><i>OWC</i> uses orbital elements provided by <strong class="text-light"><a href="https://ssd.jpl.nasa.gov/?horizons" target=_blank>Horizons</a></strong>, <strong class="text-light"><a href="https://asteroid.lowell.edu/main/astorb" target=_blank>AstOrb</a></strong>, <strong class="text-light"><A href="https://newton.spacedys.com/astdys/" target=_blank>AstDyS</a></strong> and <strong class="text-light"><a href="https://minorplanetcenter.net//iau/mpc.html" target=_blank>MPC</a></strong></span>
            </div>
            <div class="col-12 pt-2 mx-2" >
              <i class="fa fa-asterisk pr-1 yellow-highlight"></i> <span class="text-muted"><i>OWC</i> uses <strong class="text-light"><a href="https://aladin.u-strasbg.fr/" target=_blank>Aladin Sky Atlas</a></strong> for plotting star charts</span>
            </div>                   
            <div class="col-12 pt-2 mx-2" >
              <i class="fa fa-asterisk pr-1 yellow-highlight"></i> <span class="text-muted"><i>OWC</i> uses <strong class="text-light"><a href="http://simbad.u-strasbg.fr/simbad/" target=_blank>SIMBAD</a></strong> for star identification and <strong class="text-light"><a href="https://vizier.u-strasbg.fr/viz-bin/VizieR" target=_blank>VizieR</a></strong> for displaying star data</span>
            </div>      
            <div class="col-12 pt-2 mx-2" >
              <i class="fa fa-asterisk pr-1 yellow-highlight"></i> <span class="text-muted"><i>OWC</i> uses the <strong class="text-light"><a href="https://srtm.csi.cgiar.org/" target=_blank>SRTM 250m dataset</a></strong> for geo elevation data</span>
            </div>                  
            <div class="col-12 pt-2 mx-2" >
              <i class="fa fa-asterisk pr-1 yellow-highlight"></i> <span class="text-muted"><i>OWC</i> is maintained by <strong class="text-light">Hristo Pavlov</strong></span>
            </div>          
        </div>          
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data(){
    return {
      appVersion: 0,
      versionDate: ''
    }
  },
  methods: {
        ...mapActions(['loadServerVersionInfo'])
  },
  computed: {
      currentUser(){
          return this.$store.getters.currentUser;
      },
      versionInfo(){
          return this.$store.getters.serverVersionInfo;
      },
      isUserLoggedIn(){
          return this.currentUser && this.currentUser.id;
      },
      occultVersion(){
        return this.versionInfo?.OccultVersion ?? null;
      }
  },  
  mounted(){
    this.appVersion = require('../../package.json').version;
    this.versionDate = require('../../package.json').versionDate;

    if (this.isUserLoggedIn)
    {
      this.showLoader = false;
      try 
      {
          this.loadServerVersionInfo();

          this.showLoader = false;
      }
      catch(error)
      {
          this.showLoader = false;
      }      
    }
  }
}
</script>
